.article-container-login {
  display: flex;
  flex-wrap: wrap;
}

.article-login {
  flex: 0 0 20%;
  /* padding: 10px; */
}

.input-container {
  position: relative;
}

.input-field {
  padding-left: 60px;
  border-radius: 10px;
  /* width: calc(100% - 60px); */
  box-sizing: border-box;
  border: 1px solid #ccc;
  height: 60px;
  font-size: 22px;
}

.input-icon {
  position: absolute;
  left: 6%;
  top: 50%;
  transform: translateY(-50%);
}

.password-input-container {
  position: relative;
}

.password-toggle-button {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  cursor: pointer;
}
