.container_sale {
  display: flex;
  justify-content: center;
  align-items: center;
}

.article-container-sale {
  display: flex;
  flex-wrap: wrap;
}

.article-container-sale3 {
  display: flex;
  flex-wrap: wrap;
}

.article-container-sale1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2px;
}
.overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1001;
}
.article-sale {
  flex: 0 0 50%;
  padding: 10px;
}
.article-sale0 {
  flex: 0 0 70%;
  padding: 10px;
}

.article-sale1 {
  flex: 0 0 16%;
  box-sizing: border-box;
  padding: 10px;
}

.article-sale3 {
  flex: 0 0 50%;
  box-sizing: border-box;
  /* padding: 10px; */
}

.user-body {
  width: fit-content;
  height: fit-content;
  justify-content: space-around;
}

.css-13cymwt-control-sale {
  width: 100%;
  height: 100%;
}

.article-container-select {
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: space-around;
  /* flex-wrap: wrap; */
}

.card-sale {
  border: 1px solid #ddd;
  margin: 4px;
  height: 89vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px !important;
  border-radius: 10px;
  overflow: hidden; /* Hide any content that overflows the container */
}

.card-sale3 {
  border: 1px solid #ddd;
  margin: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  border-radius: 10px;
  height: 100% !important;
  overflow: hidden; /* Hide any content that overflows the container */
}

.card-sale-keypad {
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table-container-sale {
  margin: 0px !important;
  /* min-height: 400px; */
  height: 44vh;
  overflow-y: auto;
  border-collapse: collapse;
  border: 1px solid #ddd;
  border-radius: 10px;
  /* width: 100%; */
}

.table-container-sale03 {
  margin: 0px !important;
  max-width: 74vh;
  min-width: 20vh !important;
  /* 0px; */
  max-height: 64vh;
  min-height: 60vh;
  /* min-width: 800px; */
  /* overflow-y: auto; */
  overflow: scroll;
  /* overflow-x: auto; */
  /* border-collapse: collapse; */
  /* border: 1px solid #ddd; */
  /* border-radius: 10px !important; */
  /* width: 100%; */
}

.table-container-sale1 {
  margin: 0px !important;
  overflow-y: auto;
  border-collapse: collapse;
  border-radius: 10px !important;
  height: 20vh;
  /* border: 1px solid #ddd;
  border-radius: 10px !important; */
  /* width: 100%; */
}
.borderless td,
.borderless th {
  border: none;
}
.table-container-sale3 {
  margin-top: 10px;
  max-width: 499%;
  height: 480px;
  overflow-y: auto;
  border-collapse: collapse;
  border: 3px solid #ddd;
  border-radius: 12px;
  /* width: 100%; */
}

.table-container-sale4 {
  margin-top: 10px;
  max-width: 499%;
  height: 440px;
  overflow-y: auto;
  border-collapse: collapse;
  border: 3px solid #ddd;
  border-radius: 12px;
  /* width: 100%; */
}

.table-container-sale5 {
  margin-top: 10px;
  max-width: 499%;
  height: 512px;

  border-collapse: collapse;
  border: 3px solid #ddd;
  border-radius: 12px;
  /* width: 100%; */
}

.table-sale-tr {
  text-align: center !important;
  font-size: 14px !important;
  font-weight: bold;
  vertical-align: middle;
  height: 40px !important;
}

.table-sale-tr th {
  position: sticky;
  top: 0;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.table-sum-label {
  width: 10vw !important;
  text-align: right !important;
  font-weight: 600 !important;
  font-size: calc(0.4vw + 6px) !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

.table-sum-cash {
  /* width: auto !important; */
  text-align: left !important;
  padding-left: 8px !important;
  font-weight: 600 !important;
  font-size: calc(0.4vw + 6px) !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

.table-sum-tb {
  text-align: left !important;
  margin-left: 8px;
  font-weight: 600;
  font-size: calc(0.4vw + 5px) !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  width: calc(10vw + 20px) !important;
  height: 90%;
}

.table-grand-sum {
  text-align: center !important;
  font-weight: bold !important;
  padding-bottom: calc(0.4vw + 10px);
  padding-top: calc(0.4vw + 10px);
  padding-left: 8px !important;
  padding-right: 8px !important;
  max-width: 160px;
}

.tgs-label {
  font-size: 1.3vw;
  color: black;
}

.tgs-value {
  font-size: 2.5vw;
  color: white;
  word-wrap: break-word;
}

.action-btns-card {
  cursor: pointer;
  width: auto;
  height: 100px !important;
  margin: 0;
  padding: 0%;
  padding-left: 4%;
  padding-right: 4%;
  font-weight: Bold;
  font-size: calc(0.6vw + 2px);
  text-align: center;
}
.action-btn {
  display: flex;
  width: 100%;
  height: 100%;
  font-weight: bold;
  color: white;
  justify-content: center;
  align-items: center;
}

.pos-table-body {
  vertical-align: middle !important;
  font-size: 12px;
  text-align: center;
}

.pos-table-body td {
  text-align: center;
  padding: 2px;
}

.pos-table-body img {
  max-width: 100%;
  max-height: 90%;
  align-self: center;
  object-fit: contain;
}

.centered-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.centered-input input {
  width: calc(100% - 16px) !important;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 2px;
  padding-right: 2px;
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
}

.centered-input textarea {
  width: calc(100% - 16px) !important;
  text-align: left;
  font-size: 11px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;
  height: 42px;
  line-height: 14px;
}

.right-table-field {
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 6px;
}

.right-table-field label {
  font-size: calc(0.4vw + 7px);
  font-weight: 500;
}

.right-table-field input {
  width: 100% !important;
  font-size: calc(0.3vw + 7px);
}

.right-table-field textarea {
  width: 100% !important;
  font-size: calc(0.3vw + 7px);
  display: flex;
  height: 80px;
}

.keypad-grid1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* justify-content: space-between; */
}

.keypad-button1 {
  /* color: currentColor; */
  font-weight: Bold;
  font-size: 24px;
  text-align: center;
  min-height: 77px;
  margin-bottom: 12px !important;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  /* justify-content: space-between; */
  /* color: currentColor; */

  border: 2px solid #03c9d7;
  /* border-radius: 10px; */
  /* padding: 10px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* background-color: currentColor; */
}

.overlay-freeze {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 99999; /* Ensure the overlay is on top of other elements */
}

.table tr:hover td {
  background-color: #ffd7a3 !important;
  cursor: pointer;
}
