:root {
  --my-current-color: #03c9d7;
}

.custom-grid .e-headercell {
  height: 72px !important;
  color: var(--my-current-color);
  /* font-size: 18px !important; */
}
.custom-grid .e-gridpager .e-numericitem {
  background-color: #77bdff;
  color: #ffffff;
  cursor: pointer;
}
.custom-grid .e-gridpager .e-numericitem:hover {
  background-color: white;
  color: #80bdff;
}
.e-grid .e-gridpager .e-currentitem {
  background-color: #005193;
  color: #fff;
}
.custom-grid .e-headercelldiv {
  line-height: 30px !important;
  font-size: 16px !important;
  text-align: center !important;
}
.custom-grid .e-rowcell {
  height: 30px !important;
  font-size: 14px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.custom-grid .e-altrow {
  background-color: #e4faff;
}
.custom-grid .e-selectionbackground {
  background-color: #ffd7a3 !important;
}

.button-row {
  padding: 0;
  padding-left: 8px;
  padding-right: 8px;
}
.select-custom {
  min-width: 140px;
  border-width: 1px;
  border-style: solid;
  font-size: 18px;
  padding: 6px;
  margin: 6px;
}
.select-custom-rep {
  min-width: 150px;
  max-width: 180px;
  border-width: 1px;
  border-style: solid;
  font-size: 13px;
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 8px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 4px;
  height: 28px;
}
.label-rep {
  font-weight: 500;
  margin-top: 0%;
  margin-left: 2%;
  margin-bottom: 0%;
  margin-right: 0%;
  /* width: 100%; */
  padding: 0;
  font-size: 12px;
}

#BrandSelect option {
  /* Styles for each option */
  background-color: #fff; /* Background color */
  color: #000; /* Text color */
  padding: 8px; /* Padding */
}

#BrandSelect option:hover {
  /* Styles for a hovered option */
  background-color: #e0e0e0; /* Hover background color */
}

/* .input-with-label {
  position: relative;
}

.input-label {
  position: absolute;
  left: 10px; 
  top: 10%;
  transform: translateY(-50%);
  background-color: white; 
  padding: 0 5px; 
}

.input-field {
  border: 1px solid #ccc;
  min-height: 2px; */
/* padding: 8px; */
/* padding-left: 25px;  */
/* width: 200px;  */
/* min-width: 140px;
  border-width: 1px;
  border-style: solid;
  font-size: 18px;
  padding: 6px;
  margin: 6px;
} */

.custom-grid1 .e-headercell {
  height: 48px !important;
  color: var(--my-current-color);
  /* font-size: 18px !important; */
}
.custom-grid1 .e-gridpager .e-numericitem {
  background-color: #77bdff;
  color: #ffffff;
  cursor: pointer;
}
.custom-grid1 .e-gridpager .e-numericitem:hover {
  background-color: white;
  color: #80bdff;
}
.custom-grid1 .e-headercelldiv {
  line-height: 24px !important;
  font-size: 14px !important;
  text-align: center !important;
}
.custom-grid1 .e-rowcell {
  height: 24px !important;
  font-size: 13px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.custom-grid1 .e-altrow {
  background-color: #e4faff;
}
.custom-grid1 .e-selectionbackground {
  background-color: #ffd7a3 !important;
}
