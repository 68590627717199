.container-cus {
  display: flex;
  justify-content: center;
  align-items: center;
}

.article-container-cus {
  display: flex;
  flex-wrap: wrap;
}
.article-container-cus1 {
  display: flex;
  /* flex-wrap: wrap; */
  /* margin-left: 40%; */
}

.article-cus {
  flex: 0 0 40%;
  padding: 10px;
}

.article-cus-card {
  flex: 0 0 17%;
  padding: 3px;
}
.article-cus3 {
  flex: 0 0 32%;
}
.article-cus4 {
  flex: 0 0 40%;
  padding-right: 60px;
}

.article-cus2 {
  flex: 0 0 42%;
  /* justify-content: center; */
  /* box-sizing: border-box; */
  /* border: 1px solid #ddd; */

  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  /* padding: 20px; */
}

.article-cus1 {
  flex: 0 0 20%;
  justify-content: center;
  /* box-sizing: border-box; */
  /* border: 1px solid #ddd; */

  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  /* padding: 20px; */
}

.user-body {
  width: fit-content;
  height: fit-content;
  justify-content: space-around;
}

.css-13cymwt-control-cus {
  width: 100%;
  height: 100%;
}

.article-container-select1 {
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: space-around;
  /* flex-wrap: wrap; */
}

.card-cus {
  /* border: 1px solid #ddd; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  padding: 1px;
  /* margin: 10px; */
  border-radius: 3px;
  overflow: hidden;
}

.card-outerlayout {
  /* border: 1px solid #ddd; */
  background-color: white;
  display: flex;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  /* padding: 1px; */
  /* margin: 10px; */
  /* border-radius: 3px; */
  /* overflow: hidden; */
}

.card-cus-keypad {
  border: 2px solid #ddd;
  /* border-radius: 10px; */
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table-container-cus {
  margin-top: 10px;
  max-width: 499%;
  height: 490px;
  overflow-y: auto;
  border-collapse: collapse;
  border: 3px solid #ddd;
  border-radius: 12px;
  /* width: 100%; */
}

.table-container-cus1 {
  margin-top: 10px;
  max-width: 1000%;
  overflow-y: auto;
  border-radius: 12px;
  /* width: 100%; */
}

.table-cus-tr {
  /* color: #03c9d7; */
  text-align: "center";
  font-size: "20px";
  font-weight: "bold";
}

.centered-input1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.centered-input1 input {
  width: 80%;
  padding: 8px;
  box-sizing: border-box;
}

.wrapper-cus {
  height: 144px;
  display: flex;
  width: 144px;
  /* gap: 20px; */
}

/* .box-cus {
  /* border: 2px solid #000;
  /* width: 144px;
} */

.cus-image-label {
  margin-top: 40px;
  font-weight: Bold;
  font-size: 18px;
  justify-content: left;
  align-items: left;
}

.cus-image-input {
  width: 50%;
  flex-direction: column;
  justify-content: left;
  align-items: left;
}

.cus-image-card-layout {
  max-width: 100%;
  max-height: 100%;
}

.card-cus-layout {
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.textarea13 {
  height: 40px;
  width: 80%;
  padding: 8px;
  margin-bottom: 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}
